.topBar-fullBar {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: 40px;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 20000;
  padding-right: 20px;
}
.topBar-tab {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 5px;
  margin-right: 5px;
  border-radius: 5px;

}
.homeColor {
  background-image: radial-gradient(
    rgb(174, 83, 235),
    rgb(110, 42, 155)
  );
}
.storyColor {
  background-image: radial-gradient(
    rgb(255, 129, 129),
    rgb(196, 19, 19)
  );
}
.endlessColor {
  background-image: radial-gradient(
    rgb(255, 176, 93),
    rgb(223, 128, 26)
  );
}
.historyColor {
  background-image: radial-gradient(
    rgb(248, 211, 91),
    rgb(184, 152, 7)
  );
}
.dictionaryColor {
  background-image: radial-gradient(
    rgb(199, 223, 94),
    rgb(134, 160, 16)
  );
}
.creditsColor {
  background-image: radial-gradient(
    rgb(141, 230, 78),
    rgb(69, 143, 16)
  );
}
.closeColor {
  background-image: radial-gradient(
    rgb(119, 197, 187),
    rgb(45, 121, 111)
  );
}
.TB-menuButton {
  height: 35px;
  width: 125px;
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 20;
  box-shadow: 1px 1px 2px black;
}
.TB-sideBar {
  position: absolute;
  z-index: 999999;
  top: 0px;
  left: px;
  height: 100vh;
  width: 150px;
  background-color: rgba(100,150,200,.7);
  border-radius: 0px 30px 30px 0px;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-shadow: 1px 1px 2px black;
  padding-top: 10px;
}
.TB-cornerButton {
  position: absolute;
  z-index: 999999;
  top: 0px;
  left: 0px;
  height: 50px;
  width: 50px;
  background-color: rgba(86,172,238,1);
  border-radius: 0px 0px 30px 0px;
  box-shadow: 2px 2px 3px black;
  display: flex;
  justify-content: center;
  align-items: center;
}
.TB-topTabFont {
  font-size: max(min(2vmin, 35px), 15px)
}
.TB-white {
  color: white;
  font-weight: bold;
}